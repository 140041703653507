<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                            
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="row">
                                        <div class="col-6">
                                            <v-text-field class="w-50" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                        <div class="col-6 text-right">
                                            <v-btn class="mt-3 mr-3" color="green" elevation="2" small rounded @click="showDialogCreateRequest()" dark>New Employee Request<v-icon right dark>mdi-file-plus</v-icon></v-btn>
                                            <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="populateEmployeeList()">Refresh<v-icon right dark>mdi-autorenew</v-icon></v-btn>
                                        </div>
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.stat`]="{ item }">
                                <v-chip v-if="item.stat == 'O'" color="blue" small dark>OPEN</v-chip>
                                <v-chip v-else-if="item.stat == 'C'" color="red" small dark>CLOSED</v-chip>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item link>
                                            <v-list-item-title class="ma-2" @click="showDetails(item)">Details</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item link>
                                            <v-list-item-title class="ma-2" @click="printItem(item)">Print</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                            
                    </div>
                </div>

            </div>

            <v-dialog v-model="dialogCreateRequest" max-width="1280px" >
            
                <div class="card elevation-5">
                    <div class="card-body">
                        <h5 class="card-title">New Employee Request </h5>
                        <div class="row">
                            <div class="col-lg-4 col-6">
                                <v-select v-model="addCompanyIDModel" :items="addCompanyIDList" item-value="entity_id" item-text="description" label="Company" @change="(event) => addCompanyChange(event)" dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-4 col-6">
                                <v-select v-model="addPositionModel" :items="addPositionList" item-value="id_jab" item-text="descr" label="Position" :loading="addPositionLoading" dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-4 col-6">
                                <v-text-field v-model="addQtyModel" label="Quantity" @keypress="isNumber($event)" dense prepend-icon="mdi-checkbox-multiple-blank"></v-text-field>
                            </div>
                            <div class="col-lg-12 col-12">
                                <v-textarea class="pt-5" v-model="addRemarkModel" counter label="Remark" dense></v-textarea>
                            </div>
                            <div class="col-lg-12 col-12 text-right">
                                <v-btn class="mr-3" color="success" elevation="2" small rounded @click="submitEmployeeRequest()">Submit</v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            
            </v-dialog>

            <v-dialog v-model="dialogCreateRequestDetail" max-width="1280px" >
            
                <div class="card elevation-5">
                    <div class="card-body">
                        <h5 class="card-title">New Employee Request </h5>
                        <div class="row">
                            <div class="col-lg-12 col-12">
                                <v-data-table :headers="headersDetail" :items="itemListsDetail" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense></v-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            
            </v-dialog>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Viva',
                disabled: false,
                href: '/admin/viva',
                },
                {
                text: 'HRD',
                disabled: false,
                href: '#',
                },
                {
                text: 'Employee Request',
                disabled: true,
                href: '#',
                },
            ],
            headers: [
                { text: 'COMPANY', value: 'company_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DOC. ID', value: 'doc_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DATE', value: 'tr_date', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'POSITION', value: 'descr', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'QTY', value: 'ptk_req', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'COMPLETED', value: 'ptk_ready', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'REMARK', value: 'keterangan', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'STAT', value: 'stat', align: 'center', class: 'primary--text blue lighten-5' },
                { text: '', value: 'actions', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            itemLists: [],
            searchItem: '',
            loadingDatatable: false,
            dialogCreateRequest: false,
            addCompanyIDModel: '',
            addCompanyIDList: [],
            addPositionModel: '',
            addPositionList: [],
            addPositionLoading: false,
            addQtyModel: '',
            addRemarkModel: '',
            dialogCreateRequestDetail: false,
            headersDetail: [
                { text: 'COMPANY', value: 'company_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ID', value: 'idemployee', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'NAME', value: 'name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ADDRESS', value: 'address1', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CITY', value: 'city', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PROVINCE', value: 'country', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'GENDER', value: 'sex', align: 'left', class: 'primary--text blue lighten-5' },
            ],
            itemListsDetail: [],
            loadingDatatableDetail: false
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            
            this.populateEmployeeList()

            await axios.get(`${process.env.VUE_APP_URL}/api/viva/EmployeeRequest`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.addCompanyIDList = res.data.entity
                this.addPositionList = res.data.position

            })
            .catch(err => {
                
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async populateEmployeeList(){

            this.$store.dispatch('setOverlay', true)
            this.loadingDatatable = true

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/EmployeeRequest/getData`, { 



            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                
                this.$store.dispatch('setOverlay', false)
                this.loadingDatatable = false

                this.itemLists = res.data.result
                
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async submitEmployeeRequest(){

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/EmployeeRequest/submitData`, { 
                company: this.addCompanyIDModel ? this.addCompanyIDModel : '',
                position: this.addPositionModel ? this.addPositionModel : '',
                positionQty: this.addQtyModel ? this.addQtyModel : '',
                remark: this.addRemarkModel ? this.addRemarkModel : '',
            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                
                this.dialogCreateRequest = false
                this.populateEmployeeList()
                
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        showDialogCreateRequest(){

            this.dialogCreateRequest = true

        },

        async populateEmployeeList(){

            this.itemLists = []
            this.loadingDatatable = true

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/EmployeeRequest/getData`, { 



            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                
                this.$store.dispatch('setOverlay', false)
                this.loadingDatatable = false

                this.itemLists = res.data.result
                
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async addCompanyChange(id){

            this.addPositionLoading = true
            this.addPositionList = [];

            await axios.get(`${process.env.VUE_APP_URL}/api/viva/EmployeeRequest/getJabatan/?id=${id}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.addPositionLoading = false
                this.addPositionList = res.data.position

            })
            .catch(err => {
                
                this.addPositionLoading = false
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async showDetails(id){

            
            this.dialogCreateRequestDetail = true
            this.loadingDatatableDetail = true

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/EmployeeRequest/getDataDetail`, { 
                doc_id: id,
            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                
                this.loadingDatatableDetail = false
                this.itemListsDetail = res.data.result
                
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        printItem(id){



        },

        isNumber(event){
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if ((keyCode < 46 || keyCode > 57 )) { // 46 is dot
                event.preventDefault()
            }
        },

        


    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>